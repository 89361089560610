$color_1: #0099FF;
$border-color_1: #BBB;
$border-top-color_1: #0099FF;
$color_2: #FFF;
$background-color_1: #0099FF;

/* width */
/* Track */
/* Handle */
/* Handle on hover */
@keyframes spinner {
	to {
		transform: rotate(360deg);
	}
}
html {
	padding: 0;
	margin: 0;
	color: #1C2A4C !important;
	scroll-behavior: smooth;
}
body {
	padding: 0;
	margin: 0;
	color: #1C2A4C !important;
  transition: all 0.2s linear;
	scroll-behavior: smooth;
	// overflow-y: scroll !important;
}

main {
	padding: 0 !important;
	margin: 0 !important;
}

.hides {
	 display: none;
}

::-webkit-scrollbar {
	width: 5px;
}
::-webkit-scrollbar-track {
	border-radius: 5px;
}
::-webkit-scrollbar-thumb {
	background: $color_1;
	border-radius: 5px;
	&:hover {
		background: $color_1;
	}
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

a {
	color: $color_1;
	text-decoration: none;
	word-break: break-all;
}
fieldset {
	border-radius: 16px !important;
	border-color: $border-color_1;
}

.spinner {
	&:before {
		content: '';
		box-sizing: border-box;
		position: absolute;
		width: 83px;
		height: 83px;
		margin-left: -6.5px;
		margin-top: -6.5px;
		border-radius: 50%;
		border: 3px solid #fff;
		border-top-color: $border-top-color_1;
		animation: spinner 1s linear infinite;
		z-index: 999;
	}
}
@media (min-width: 899px) {
	.hide {
		display: none;
	}
}
@media (max-width: 899px) {
	.hidden {
		display: none !important;
	}
	.imgclass {
		width: 170px;
	}
	.width-m {
		width: 170px !important;
	}
}

@media (max-width: 1200px) {
	.hidden-lg {
		display: block !important;
	}
	.hide-lg {
		display: none !important;
	}
}
@media (min-width: 1200px) {
	.hidden-lg {
		display: none !important;
	}
	.hide-lg {
		display: block !important;
	}
}


/*  target the elemenent after the label*/
.checkbox {
	opacity: 0;
	display: none;
	&:checked {
		+ {
			.label {
				.ball {
					transform: translateX(15px);
				}
			}
		}
	}
}
.label {
	width: 37px;
	height: 22px;
	display: flex;
	border-radius: 50px;
	align-items: center;
	justify-content: space-between;
	position: relative;
	transform: scale(1.5);
	cursor: pointer;
	margin-left: auto;
	margin-right: 15px;
	img {
		width: 15px;
	}
}
.ball {
	width: 18px;
	height: 18px;
	top: 2px;
	left: 2px;
	position: absolute;
	border-radius: 50%;
	transition: transform 0.1s linear;
	background: #FFFFFF;
	box-shadow: 0px 4.68727px 12.4994px rgba(0, 0, 0, 0.15), 0px 4.68727px 1.56242px rgba(0, 0, 0, 0.06);
}

.css-1bm7zxb {
	margin: 10px 0 !important
}

.slick-arrow {
	display: none !important;
}

.slick-dots li button:before {
  color: #0099FF !important;
	font-size: 10px !important;
}

.react-calendar__navigation__prev2-button, .react-calendar__navigation__next2-button {
	display: none;
}

.fc-toolbar-title {
	text-transform: uppercase;
}

.fc-scrollgrid {
	border: none !important;
	text-align: center !important;
}

.fc-daygrid-day-frame {
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
}

.fc-daygrid-day-number {
	color: #BBBBBB !important;
}

.fc-button-primary, .fc .fc-button-primary:focus, .fc .fc-button:focus {
	color: #0099FF !important;
	border: none !important;
	background-color: transparent !important;
	box-shadow: none !important;
}

.fc .fc-daygrid-day.fc-day-today, .fc-h-event {
	background-color: transparent !important;
	border: none !important;
}

.fc .fc-daygrid-day.fc-day-today .fc-daygrid-day-number {
	color: #0099FF !important;
}

.fc-theme-standard td, .fc-theme-standard th {
	border: none !important;
}

.fc .fc-cell-shaded, .fc .fc-day-disabled, .fc .fc-scrollgrid-section-sticky > * {
	background: transparent !important;
}

.fc .fc-col-header-cell-cushion {
	font-weight: normal !important;
	margin-bottom: 1rem;
}

.fc-daygrid-day-frame {
	flex-direction: column !important;
}

.fc .fc-day-disabled {
	visibility: hidden !important;
}

.fc .fc-day-disabled .fc-daygrid-day-events {
	min-height: 0 !important;
}

.fc .fc-daygrid-body-natural .fc-daygrid-day-events {
	margin-bottom: 0 !important;
}

.fc-header-toolbar {
	margin: 0;
	-webkit-flex-shrink: 0;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	border-width: 0;
	border-style: solid;
	border-color: rgba(143, 157, 193, 0.2);
	border-bottom-width: thin;
	margin-top: 0 !important;
	margin-bottom: 12px !important;
	padding-bottom: 8px;
}

.fc-daygrid-day {
	cursor: pointer;
}

.fc-daygrid-day:hover {
	background-color: rgba(0, 163, 255, 0.1) !important;
	border-radius: 10px;
}

.fc-toolbar-title {
	font-size: 1.5rem !important;
}